import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
const Project = Loadable(lazy(() => import('views/project')));
const ProjectView = Loadable(lazy(() => import('views/common/project-view')));
const User = Loadable(lazy(() => import('views/pages/user')));
const Resource = Loadable(lazy(() => import('views/pages/resource')));
const ResourcePreview = Loadable(lazy(() => import('views/pages/resource/preview')));

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/a/project',
      element: <Project />,
    },
    {
      path: '/a/project/review',
      element: <Project />,
    },
    {
      path: '/a/project/review/:projectName',
      element: <ProjectView />,
    },
    {
      path: '/a/project/:projectName',
      element: <ProjectView />,
    },
    {
      path: '/a/user',
      element: <User />,
    },
    {
      path: '/a/resource',
      element: <Resource />,
    },
    {
      path: '/a/resource/:id',
      element: <ResourcePreview />, 
    }

  ],
};

export default MainRoutes;
