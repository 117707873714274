// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  resourceList: [],
  resource: null,
  total: 0,
  error: null,
};

const slice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getResourceSuccess(state, action) {
      state.resourceList = action.payload.items;
      state.totalPage = action.payload.total;
    },
    fetchResourceSuccess(state, action) {
      state.resource = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { getResourceSuccess, hasError, fetchResourceSuccess } = slice.actions;

export function getResourceList(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/api/v1/resources/list`, payload);
      dispatch(getResourceSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function addResource(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/api/v1/resources`, payload);
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
export function updateResource(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/api/v1/resources/${payload?.id}`,
        payload
      );
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getResourceById(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/v1/resources/${id}`);
      dispatch(fetchResourceSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteResource(resourceId) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/api/v1/resources/${resourceId}`);
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}