import { memo, useEffect, useMemo, useState } from 'react';

// material-ui
import {
  Box,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  Divider,
  ListItemIcon,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ProfilePlaceHolder from 'assets/images/other/profile-placeholder.jpg';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from '../MenuList';

import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import LogoSection from 'ui-component/logoSection';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProfile } from 'store/slices/Auth';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.auth.authData.data);
  const { profile } = useSelector((state) => state.auth);
  const { fileResponse } = useSelector((state) => state.upload);
  const [profilePic, setProfilePic] = useState(ProfilePlaceHolder);

  const { pathname } = useLocation();
  const prefix = pathname.split('/')[1];
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const { drawerType } = useConfig();

  const logo = useMemo(
    () => (
      <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
        <LogoSection />
      </Box>
    ),
    []
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    navigate('/login');
  };
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (fileResponse) {
      setProfilePic(fileResponse);
    } else if (profile.profileImage) {
      setProfilePic(profile.profileImage);
    } else {
      setProfilePic(ProfilePlaceHolder);
    }
  }, [fileResponse, profile.profileImage]);

  const drawerContent = (
    <>
      <MenuList />
    </>
  );

  const handleEdit = () => {
    setAnchorEl(null);
    navigate('/p/personal-information');
  };
  const goProjectList = () => {
    setAnchorEl(null);
    navigate('/a/project');
  };

  const userContent = (
    <>
      <Box sx={{ flexGrow: 1 }} />

      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Stack direction={'row'} gap={1.5} alignItems={'center'}>
          <Box
            component={'img'}
            src={profilePic}
            sx={{ height: '46px', width: '46px', borderRadius: '50%' }}
          />
          <Stack gap={0.5}>
            <Typography variant="h4">{user?.fullName}</Typography>
            <Typography variant="h6" sx={{ color: '#23232299' }}>
              {user?.userType?.name}
            </Typography>
          </Stack>
        </Stack>

        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              borderRadius: 8,
              marginTop: '-70px',
              marginLeft: '30px',
              minWidth: '200px',
            },
          }}
        >
          {/* {prefix === 'p' ? (
            <MenuItem onClick={goProjectList}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Project list</Typography>
            </MenuItem>
          ) : (
            <>
              <>
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Edit Profile</Typography>
                </MenuItem>
              </>
            </>
          )}
          <Divider /> */}

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon
                fontSize="small"
                sx={{ color: theme.palette.error.main }}
              />
            </ListItemIcon>
            <Typography
              variant="inherit"
              sx={{ color: theme.palette.error.main }}
            >
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      </Stack>
    </>
  );

  const drawerSX = {
    paddingLeft: drawerOpen ? '16px' : 0,
    paddingRight: drawerOpen ? '16px' : 0,
    marginTop: drawerOpen ? 0 : '20px',
    display: 'flex',
    flexDirection: 'column',
  };

  const drawer = useMemo(
    () => (
      <>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? '86vh' : 'calc(100vh - 115px)',
            marginBottom: !matchUpMd ? '30px' : '0',
            ...drawerSX,
          }}
        >
          {drawerContent}
          {userContent}
        </PerfectScrollbar>
      </>
    ),
    [
      matchUpMd,
      drawerOpen,
      drawerType,
      anchorEl,
      theme.palette.error.main,
      profilePic,
    ]
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          '& .MuiDrawer-paper': {
            mt: matchDownMd ? 0 : 11.5, //92
            zIndex: 1099,
            width: drawerWidth,
            background: theme.palette.background.bgMain,
            color: theme.palette.text.primary,
            borderRight: '1px solid #2323221A',
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {matchDownMd && logo}
        {drawer}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);
