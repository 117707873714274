import Visibility from '@mui/icons-material/Visibility';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
  styled,
} from '@mui/material';

import SecondaryButton from 'ui-component/button/SecondaryButton';
import { Link, useNavigate } from 'react-router-dom';
import DarkButton from 'ui-component/button/DarkButton';
import { dispatch } from 'store';
import { login, logoutSuccess } from 'store/slices/Auth';
import { openSnackbar } from 'store/slices/snackbar';
import { activeItem } from 'store/slices/menu';
import ReCAPTCHA from 'react-google-recaptcha';
import { useEffect, useState } from 'react';
import { border } from '@mui/system';

const AuthTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    background: "rgba(000, 000, 000, 0.1)",
    border: "0.75px solid #F6F6F6",
    borderRadius: '10px',
    "&:hover": {
      borderColor: theme.palette.secondary.main,
    },

    "& svg": {
      color: theme.palette.background.paper,
    },

    ...theme.typography.h4,
    "& .MuiOutlinedInput-notchedOutline": { 
      borderRadius: '10px',
      border: " solid #FFFFFF1A",
    },
  },
  "& .MuiOutlinedInput-input": {
    background: "transparent",
    border: "solid transparent",
    color: theme.palette.background.paper,
    borderRadius: '10px',

    "&::placeholder": {
      color: "#FFFFFF99",
    },

    ":-webkit-autofill": {
      "-webkit-text-fill-color": "white",
      transition: "background-color 5000s ease-in-out 0s",
    },
  },
}));
const AuthLogin = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [credentials, setCredentials] = useState({
    email: '',
    emailError: '',
    password: '',
    passwordError: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logoutSuccess());
    localStorage.removeItem('token');
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    setIsLoader(true);

    // Check if email and password are provided
    if (!credentials.email || !credentials.password) {
      setIsLoader(false);
      dispatch(
        openSnackbar({
          open: true,
          message: 'Please provide both email and password',
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        })
      );
      return;
    }

    dispatch(login(credentials))
      .then((res) => {
        setTimeout(() => {
          if (res.data?.userType?.userTypeId === 3) {
            navigate('/a/project');
          }
          dispatch(
            openSnackbar({
              open: true,
              message: 'Login Successful',
              variant: 'alert',
              alert: {
                color: 'success',
              },
              close: false,
            })
          );
        }, 500);
      })
      .catch((error) => {
        setIsLoader(false);
        dispatch(
          openSnackbar({
            open: true,
            message: error.success,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          })
        );
      });
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeRecaptcha = (value) => {
    setIsVerify(value);
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <InputLabel>
            <Typography
              variant="h4"
              fontWeight={500}
              color={'background.paper'}
            >
              E-mail Address
            </Typography>
          </InputLabel>

          <AuthTextField
            mt={2}
            fullWidth
            name="email"
            placeholder="Enter your email"
            value={credentials?.email}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            <Typography
              variant="h4"
              fontWeight={500}
              color={'background.paper'}
            >
              Password
            </Typography>
          </InputLabel>
          <AuthTextField
            name="password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            sx={{
              borderRadius: '10px',
              'input::placeholder': {
                fontSize: '56px',
                letterSpacing: '-6px',
              },
            }}
            onChange={handleChange}
            placeholder="........."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required // Add required attribute here
          />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked color="warning" />}
                label={
                  <Typography
                    variant="h4"
                    fontWeight={400}
                    color={'background.paper'}
                  >
                    Remember me
                  </Typography>
                }
              />
            </FormGroup>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_ReCAPTCHA_KEY}
              onChange={onChangeRecaptcha}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mt: 4 }}>
            <SecondaryButton
              title="login"
              disabled={!isVerify}
              isLoader={isLoader}
              onClick={handleClick}
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthLogin;
